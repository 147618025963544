@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: Poppins;
  margin: 0;
}

@layer components {
  .container {
    @apply max-w-[1150px] mx-auto;
  }
}

.container-outer {
  @apply px-8 md:px-12;
}

.darker-bg {
  @apply relative before:bg-black before:opacity-30 before:inset-0 before:absolute;
}
